<template>
  <div ref="map">
    <WrapModal
      :tooSmall="true"
      :width="800"
      :visible="gdMapVisible"
      :modealTitle="gdMapTitle"
      :maskClosable="true"
      @ok="submit"
      @cancel="cancel"
      :getContainer="() => $refs.map"
    >
      <a-spin tip="加载中..." :spinning="spinning">
        <section class="map-modal">
          <template v-if="map">
            <a-select
              allowClear
              show-search
              id="tipInput"
              :placeholder="gdMapPlaceholder"
              v-model:value="addressId"
              :show-arrow="false"
              :filter-option="false"
              :default-active-first-option="false"
              :not-found-content="null"
              class="sreach"
              @search="handleSearch"
              @change="handleChange"
            >
              <template #prefix>
                <img src="@/assets/svg/search.svg" />
              </template>
              <a-select-option v-for="item in searchlist" :key="item.id">
                <div>
                  <p :title="item.name">{{ item.name }}</p>
                  <p style="color: #999999; font-size: 12px" :title="item.address">{{ item.address }}</p>
                </div>
              </a-select-option>
            </a-select>
            <i class="iconfont icon-Shape icons" />
          </template>

          <div id="container" style="width: 752px; height: 377px"></div>
        </section>
      </a-spin>
    </WrapModal>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, watch, onMounted } from 'vue'
import WrapModal from '../WrapModal'
import { initMap, setCenter } from './map'

let map, AMap, placeSearchComponent, marker, geocoder

export default defineComponent({
  components: {
    WrapModal
  },
  name: 'GdMap',
  props: {
    gdMapVisible: {
      type: Boolean,
      default: false
    },
    selectAddress: {
      type: Object,
      default: () => ({})
    },
    gdMapTitle: {
      type: String,
      default: '标题'
    },
    gdMapPlaceholder: {
      type: String,
      default: '请输入'
    }
  },
  setup(props, { emit }) {
    const spinning = ref(false)
    const addressId = ref(undefined)
    const state = reactive({
      selected: {},
      searchlist: [],
      marker: null,
      center: [120.201846, 30.185021],
      currentCityCode: '0571'
    })

    // 初始化地图
    const init = () => {
      spinning.value = true
      if (Object.keys(props.selectAddress).length > 0) {
        state.center = [props.selectAddress.lng, props.selectAddress.lat]
      } else {
        state.center = [120.201846, 30.185021]
      }
      initMap(state.center)
        .then(obj => {
          map = obj.map
          AMap = obj.AMap

          const controlBar = new AMap.ControlBar({
            showZoomBar: true,
            showControlButton: false,
            position: { top: '10px', right: '10px' }
          })
          map.addControl(controlBar)
          mapSearchInit()
          initMarker()
          initClick()
          setMarker(state.center)

          geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all'
          })
          getAddress(state.center)
          setCityCode(state.center)
          spinning.value = false
        })
        .catch(e => {
          spinning.value = false
          console.log(e)
        })
    }

    watch(
      () => props.gdMapVisible,
      newValue => {
        if (newValue) {
          init()
        } else {
          map && map.destroy()
          map = null
          AMap = null
        }
      }
    )

    // 搜索初始化
    const mapSearchInit = () => {
      // 注册placeSearch组件
      placeSearchComponent = new AMap.PlaceSearch({
        // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
        city: state.currentCityCode
      })
    }
    // 添加标记
    const initMarker = position => {
      AMap.event.addListener(map, 'click', function(e) {
        //添加点击事件,传入对象名，事件名，回调函数
        state.center = [e.lnglat.lng, e.lnglat.lat]
        getAddress([e.lnglat.lng, e.lnglat.lat])
        setMarker(state.center)
      })
      marker = new AMap.Marker({
        icon: new AMap.Icon({
          image: require('@/assets/images/dw.png'), //图片ip,
          size: new AMap.Size(20, 20), //图标大小
          imageSize: new AMap.Size(20, 20)
        }),
        imageSize: '12px',
        position,
        offset: new AMap.Pixel(-13, -30),
        // 设置是否可以拖拽
        draggable: true,
        cursor: 'move',
        // 设置拖拽效果
        raiseOnDrag: true
      })
      marker.setMap(map)
    }
    // 设置点位
    const setMarker = position => {
      if (!marker) return
      marker.setPosition(position)
      setCenter(map, position)
    }
    // 点选 获取位置信息
    const getAddress = lnglatXY => {
      geocoder &&
        geocoder.getAddress(lnglatXY, function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            let address = result.regeocode.addressComponent
            state.currentCityCode = address.citycode
            state.selected = {
              name: result.regeocode.formattedAddress,
              lng: state.center[0],
              lat: state.center[1],
              address: address.province + address.city + address.township + address.street + address.streetNumber
            }
          }
        })
    }
    // 获取城市code
    const setCityCode = lnglatXY => {
      geocoder &&
        geocoder.getAddress(lnglatXY, function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            state.currentCityCode = result.regeocode.addressComponent.citycode
            placeSearchComponent && placeSearchComponent.setCity(state.currentCityCode)
          }
        })
    }

    // 初始化地图点击事件，功能：设置标记点和获取地址
    const initClick = () => {
      AMap.event.addListener(map, 'click', function(e) {
        //添加点击事件,传入对象名，事件名，回调函数
        state.center = [e.lnglat.lng, e.lnglat.lat]
        getAddress([e.lnglat.lng, e.lnglat.lat])
        setMarker(state.center)
      })
    }
    /** 关键词搜索 */
    const handleSearch = val => {
      placeSearchComponent.search(val, function(status, result) {
        // 查询成功时，result即对应匹配的POI信息
        state.searchlist = []
        if (result.info == 'OK') {
          state.searchlist = result.poiList.pois
        }
      })
    }
    // 选择搜索结果
    const handleChange = val => {
      if (!val) {
        state.searchlist = []
        return
      }
      let obj = state.searchlist.filter(item => item.id == val)[0]
      state.selected = {
        name: obj.name,
        lng: obj.location.lng,
        lat: obj.location.lat,
        address: obj.address
      }
      state.center = [obj.location.lng, obj.location.lat]
      setCityCode(state.center)
      setMarker(state.center)
    }
    // 确定
    const submit = () => {
      if (!spinning.value) {
        addressId.value = undefined
        state.searchlist = []
        emit('addressChange', state.selected)
        emit('update:gdMapVisible', false)
      }
    }
    // 取消
    const cancel = () => {
      addressId.value = undefined
      state.searchlist = []
      emit('update:gdMapVisible', false)
    }

    return {
      spinning,
      map,
      addressId,
      cancel,
      submit,
      handleSearch,
      handleChange,
      ...toRefs(state)
    }
  }
})
</script>

<style lang="scss" scoped>
.map-modal {
  position: relative;
  .icons {
    position: absolute;
    top: 21px;
    left: 32px;
    z-index: 11;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
  }
  .sreach {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 10;
    width: 320px;
  }
  :deep(.ant-select-single .ant-select-selector .ant-select-selection-placeholder) {
    padding-left: 27px;
  }
  :deep(.ant-select-single .ant-select-selector .ant-select-selection-search-input) {
    padding-left: 27px;
  }
  :deep .ant-select-selection-item {
    padding-left: 27px;
  }
  :deep .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
  }
  :deep .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: text;
  }
}
</style>
